import { Flex, Text, Box } from '@chakra-ui/react'
import React from 'react'
import { IGroup, Location } from '../../../api/types'
import { deviceGroupSumByProperty } from '../../../../../utils/helpers'
import GroupUnfo from './GroupInfo'

type Props = {
    location: Location
}

// Generate a dynamic blue color palette
function generateColors(count: number): string[] {
    const colors = [];
    const startHue = 200;
    const saturation = 70;
    const lightnessStep = 30 / count;

    for (let i = 0; i < count; i++) {
        const lightness = 50 + i * lightnessStep;
        const color = `hsl(${startHue}, ${saturation}%, ${lightness}%)`;
        colors.push(color);
    }
    return colors;
}

function isConsumationGroup(group: IGroup, property: string) {
    return deviceGroupSumByProperty(group.devices, property) > 0
}

function getTopGroupsTotal(location: Location, deviceProperty: string, locationProperty: string) {
    const filteredGroups = location.groups.filter((group) => isConsumationGroup(group, deviceProperty))

    if (filteredGroups.length === 0) {
        return []
    }

    const groupConsumptions = filteredGroups?.map(group => {
        const excludeIsMain = group.devices.filter((device) => !device.device_is_main)
        const total = excludeIsMain.reduce((sum, device) => sum + device[deviceProperty], 0);
        return {
            group_name: group.group_name,
            total: total,
        };
    });

    const sortedGroups = groupConsumptions?.sort((a, b) => b.total - a.total);

    const topGroups = sortedGroups?.slice(0, 4).map(group => ({
        group_name: group.group_name,
        total: group.total,
        percentage: Math.round((group.total / location[locationProperty]) * 100),
    }));

    return topGroups;
}

function getLocationsPercentages(location: Location, current: string, previous: string) {
    const currentValue = location[current]
    const previousValue = location[previous]

    if (currentValue > 0 && previousValue > 0) {
        const percentageChange = ((previousValue - currentValue) / previousValue) * 100
        return Number(percentageChange.toFixed(2));
    }
    return 0;
}

export const GroupsConsumption = (props: Props) => {
    const { location } = props

    const topConsumationGroups = getTopGroupsTotal(location, "device_consumption", "location_total_consumption")
    const consumationGroupNames = topConsumationGroups.map((group) => group.group_name)
    const consumationGroupPercents = topConsumationGroups.map((group) => group.percentage)

    // Generate dynamic colors based on the number of groups
    const consumationDynamicColors = generateColors(consumationGroupNames.length);
    const consumationPercentageChange = getLocationsPercentages(location, "location_total_consumption", "previous_location_total_consumption")


    const topProducersGroups = getTopGroupsTotal(location, "device_generated_power", "location_total_generated_power")
    const producersGroupNames = topProducersGroups.map((group) => group.group_name)
    const producersGroupPercents = topProducersGroups.map((group) => group.percentage)

    // Generate dynamic colors based on the number of groups
    const producersDynamicColors = generateColors(producersGroupNames.length);
    const producersPercentageChange = getLocationsPercentages(location, "location_total_generated_power", "previous_location_total_generated_power")

    return (
        <Flex width="100%" pt={4} direction="column" gap="70px">
            {topConsumationGroups.length > 0 && (
                <Box>
                    <Text mb="20px">Consumer groups:</Text>
                    <GroupUnfo
                        dynamicColors={consumationDynamicColors}
                        groupNames={consumationGroupNames}
                        groupPercents={consumationGroupPercents}
                        percentageChange={consumationPercentageChange}
                        topGroups={topConsumationGroups}
                        total={location.location_total_consumption}
                    />
                </Box>
            )}
            {topProducersGroups.length > 0 && (

                <Box>
                    <Text mb="20px">Generator groups:</Text>
                    <GroupUnfo
                        dynamicColors={producersDynamicColors}
                        groupNames={producersGroupNames}
                        groupPercents={producersGroupPercents}
                        percentageChange={producersPercentageChange}
                        topGroups={topProducersGroups}
                        total={location.location_total_generated_power}
                    />
                </Box>
            )}
        </Flex>
    )
}