import React, { useMemo } from 'react'
import {
  Flex,
  Box,
  Grid,
  GridItem,
  Heading,
  Text,
  Button,
  Tooltip,
} from '@chakra-ui/react'
import Header from '../../../app/components/Header'

import { TileWithIcon, TileWithIconContainer } from '../../../app/components/Tile'
import { ConsumedEnergyIcon } from '../../../assets/icons/ConsumedEnergy'
import { ProgressBarWrapper } from '../../../app/components/ProgressBar'
import ProducedEnergyIcon from '../../../assets/icons/ProducedEnergyIcon'
import { useGetAllLocationsQuery, useGetReportDataQuery } from '../api/reportsApi'
import {
  LocationTabStrip,
  TabStripData,
} from '../../dashboard/components/LocationTabStrip'
import StatsAll from '../components/StatsAll'
import { addCommasToNumber, calculatePercentage } from '../../../utils/helpers'
import { useParams, useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import { Download } from '../../../assets/icons/Download'
import { Bookmark } from '../../../assets/icons/Bookmark'
import { RecurringReport } from '../../../assets/icons/RecurringReport'
import { MailIcon } from '../../../assets/icons/Mail'
import { useGetReportAsPDFMutation } from '../../../app/services/api/api'

const Report = () => {
  const { locationIds: locationIdsEncoded } = useParams()

  const location = useLocation()
  const from = new URLSearchParams(location.search).get('from')
  const to = new URLSearchParams(location.search).get('to')
  const locationIds = atob(locationIdsEncoded).split(', ')
  const locationId = new URLSearchParams(location.search).get('location')
  const { data: locations, error: getLocationsError } = useGetAllLocationsQuery()

  const selectedLocations = useMemo(
    () =>
      locations?.results.filter((loc) =>
        locationIds.some((id) => id === loc.id.toString()),
      ),
    [locations?.results, locationIds],
  )

  const locationIdsParameter = useMemo(
    () => (locationId ? [locationId] : locationIds),
    [locationId, locationIds],
  )

  const {
    data: baseReportData,
    isLoading: isBaseReportLoading,
    error: baseReportDataError,
  } = useGetReportDataQuery({
    from_date: from,
    to_date: to,
    location_ids: locationIdsParameter,
  })

  const topFiveDevices = baseReportData?.devices

  const topFiveDevicesTotalConsumption = useMemo(
    () => topFiveDevices?.reduce((acc, device) => (acc += device.consumption), 0) || 0,
    [topFiveDevices],
  )

  const [getReportAsPDF, { isLoading, error }] = useGetReportAsPDFMutation()

  const handleDownload = () => {
    getReportAsPDF({
      from_date: from,
      to_date: to,
      location_ids: locationIdsParameter,
      exclude_hidden: true,
    })
  }

  return (
    <>
      <Box mb="24px">
        <Header
          children={
            <Flex justifyContent="space-between">
              <Flex direction="column" wrap="nowrap">
                <Text fontSize="24px" fontWeight="700">
                  {format(new Date(from), 'd MMM yyyy')} -
                  {format(new Date(to), 'd MMM yyyy')}
                </Text>
                <Text>Energy consumption report</Text>
              </Flex>
            </Flex>
          }
        />
      </Box>

      <Flex justifyContent="space-between" alignItems="center">
        <LocationTabStrip data={(selectedLocations as unknown as TabStripData[]) || []} />
        <Flex gap="8px">
          <Tooltip label="Unavailable" placement="left" hasArrow>
            <Button padding="12px" cursor="pointer" background="transparent" disabled>
              <MailIcon boxSize="24px" />
            </Button>
          </Tooltip>
          <Tooltip label="Unavailable" placement="left" hasArrow>
            <Button padding="12px" cursor="pointer" background="transparent" disabled>
              <RecurringReport boxSize="24px" />
            </Button>
          </Tooltip>
          <Tooltip label="Unavailable" placement="left" hasArrow>
            <Button padding="12px" cursor="pointer" background="transparent" disabled>
              <Bookmark boxSize="24px" />
            </Button>
          </Tooltip>

          <Tooltip label="Download report" placement="left" hasArrow>
            <Button
              padding="12px"
              cursor="pointer"
              background="transparent"
              onClick={() => handleDownload()}
            >
              <Download boxSize="24px" />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
      <Grid gridTemplateColumns="repeat(3, 1fr)" gap="16px">
        <GridItem colSpan={2}>
          <Flex direction="column" gap="16px">
            <Flex background="white" alignItems="center" px="16px" rounded="2xl" w="full">
              <StatsAll
                isBaseReportLoading={isBaseReportLoading}
                baseReportData={baseReportData}
                totalConsumption={baseReportData?.total_consumption}
              />
            </Flex>

            <Flex
              background="white"
              alignItems="flex-start"
              p="24px"
              direction="column"
              rowGap="16px"
              rounded="2xl"
            >
              <Flex justifyContent="space-between" w="100%">
                <Box>
                  <Heading
                    fontWeight={700}
                    color="#484848"
                    fontSize="24px"
                    textAlign="left"
                  >
                    Top Five Consumers
                  </Heading>
                  <Text fontSize="12px" color="#9B9B9B">
                    All locations
                  </Text>
                </Box>

                <Flex>
                  {/* <Box>
                    <Text color="#707070" fontSize="14px">
                      Carbon Footprint
                    </Text>
                    <Flex alignItems="center">
                      <Text fontSize="16px" fontWeight={700}>
                        183
                      </Text>
                      <Box m="2px" />
                      <Text color="#707070" fontSize="14px">
                        g
                      </Text>
                      <Text color="#21D17F" ml={2}>
                        <TrendDownIcon /> 0.69%
                      </Text>
                    </Flex>
                  </Box> */}
                  {/* <Box ml="24px">
                    <Text color="text.secondary" fontSize="14px">
                      Total Consumed Energy
                    </Text>
                    <Flex>
                      <Flex alignItems="center">
                        <Text fontSize="16px" fontWeight={700}>
                          {addCommasToNumber(topFiveDevicesTotalConsumption)}
                        </Text>
                        <Box m="2px" />
                        <Text color="text.secondary" fontSize="14px">
                          {KILOWATT_HOUR}
                        </Text>
                      </Flex>
                      {/* <Text color="#21D17F" ml={2}>
                        <TrendDownIcon /> 0.69%
                      </Text> 
                    </Flex>
                  </Box> */}
                </Flex>
              </Flex>
              {topFiveDevices?.map((device, index) => (
                <ProgressBarWrapper
                  key={index}
                  kilowatts={device.consumption}
                  percentage={calculatePercentage(
                    device.consumption,
                    topFiveDevicesTotalConsumption,
                    1,
                  )}
                  title={device?.title}
                  variant="consumer"
                />
              ))}
              {/* <ProgressBarWrapper
                kilowatts={553.23}
                percentage={43}
                title="Applience 1"
              />
              <ProgressBarWrapper
                kilowatts={523.23}
                percentage={33}
                title="Applience 2"
              />

              <ProgressBarWrapper
                kilowatts={523.23}
                percentage={33}
                title="Applience 4"
              /> */}
            </Flex>
          </Flex>
        </GridItem>

        {/* TODO: Fix items */}
        <GridItem colSpan={1}>
          <Flex gap="16px" direction="column">
            <TileWithIconContainer>
              <TileWithIcon
                name="Total consumed energy"
                value={addCommasToNumber(baseReportData?.total_consumption || 0)}
                Icon={ConsumedEnergyIcon}
                // percentage="0.69"
                trend="up"
              />
              <Box borderBottom="1px solid #DEDFE2" w="100%" />
              <TileWithIcon
                name="Total produced energy"
                value={addCommasToNumber(baseReportData?.total_generated_power || 0)}
                Icon={ProducedEnergyIcon}
                // percentage="0.69"
                trend="up"
              />
            </TileWithIconContainer>
            {/* <TileWithIconContainer>
              <TileWithIcon
                name="Cost for consumed energy"
                value="4,632.16"
                Icon={CostForConsumptionIcon}
                percentage="0.69"
                trend="down"
              />
              <Box borderBottom="1px solid #DEDFE2" w="100%" />
              <TileWithIcon
                name="Generated income through renewables"
                value="4,632.16"
                Icon={GeneratedIncomeIcon}
                percentage="0.69"
                trend="down"
              />
            </TileWithIconContainer> */}
            {/* <TileWithIconContainer>
              <TileWithIcon
                name="Carbon footprint"
                value="4,632.16"
                Icon={CloudIcon}
                // percentage="0.69"
                trend="down"
              />
              <Box borderBottom="1px solid #DEDFE2" w="100%" />
              <TileWithIcon
                name="Carbon intensity"
                value="4,632.16"
                Icon={CarbonIntensityIcon}
                // percentage="0.69"
                trend="up"
              />
            </TileWithIconContainer> */}
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default Report
