import { Flex, Spinner, Stack } from "@chakra-ui/react"
import React from "react"
import { ILocationResult } from "../../api/locations"
import { EnergyReportTitlePage } from "./EnergyReportComponents/EnergyReportTitlePage"
import { EnergyReportBody } from "./EnergyReportComponents/EnergyReportBody"
import backgroundImg from '../../../../assets/report/background.png'
import { useGetEnergyConsumptionReportQuery } from "../../api/reportsApi"
import { ReportData } from "../../types"

type Props = {
  locations: ILocationResult[]
  startDate: Date;
  endDate: Date;
  group_id: any[],
  device_ids: any[],
  ibex_index: number,
  carbon_intensity: number,
  isDownloading: boolean
}

export const EnergyConsumptionReportPdf = ({ locations, startDate, endDate, device_ids, group_id, ibex_index, carbon_intensity, isDownloading }: Props) => {
  const locationsIds = locations?.map((x) => x.id.toString()) || []
  const groupsIds = group_id?.map((x) => x.id?.toString()) || []
  const deviceIDs = device_ids?.map((x) => x.toString()) || []
  const energyConsumptionParameters: ReportData.QueryParams = {
    from_date: startDate.toLocaleDateString('en-US'),
    to_date: endDate.toLocaleDateString('en-US'),
    location_ids: locationsIds,
    groups_id: groupsIds,
    device_ids: deviceIDs,
    ibex_index: ibex_index?.toString(),
    carbon_intensity: carbon_intensity?.toString(),
  }
  const { data, isLoading } = useGetEnergyConsumptionReportQuery(energyConsumptionParameters)
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      backgroundImage={`url('${backgroundImg}')`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="left"
    >
      <Flex direction="column" justifyContent="center" alignItems="center" width="100%" height="100%">
        {isLoading || isDownloading ? (
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        ) :
          <>
            <EnergyReportTitlePage startDate={startDate} endDate={endDate} locations={data} />
            <EnergyReportBody startDate={startDate} endDate={endDate} locations={data} />
          </>}
      </Flex>
    </Stack>
  )
}