import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
} from '@chakra-ui/react'
import html2pdf from 'html2pdf.js'
import TrendReportPdf from './PdfComponents/TrendReportPdf'
import { ILocationResult } from '../../api/locations'
import { IReportPdfResponse } from '../../api/trendReportPdf'
import { ReportPeriodType } from '../GenerateReport'
import React, { useState } from 'react'
import { EnergyConsumptionReportPdf } from './EnergyConsumptionReportPdf'

const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    background: 'gray.200',
    borderRadius: '0 0.25em 0.25em 0',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'blue.500',
    borderRadius: '0.25em',
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
  },
}

type PreviewDocumentDialogProps = {
  locations: ILocationResult[]
  startDate: any
  endDate: any
  reportType: number
  reportName: string
  pdfReportData: IReportPdfResponse
  reportPeriodType: ReportPeriodType
  groupId: any[]
  deviceIds: any[]
  ibex_index: number
  carbon_intensity: number
  isOpen: boolean
  onClose: () => void
}

const PreviewDocumentDialog = (props: PreviewDocumentDialogProps) => {
  const { startDate, endDate, locations, reportType, reportName, pdfReportData, reportPeriodType, groupId, deviceIds, ibex_index, carbon_intensity, isOpen, onClose } = props

  const [isDownloading, setIsDownloading] = useState(false)

  const onDownload = () => {
    const component = document.getElementById('pdf-container');
    if (!component) return;
  
    const options = {
      margin: 0,
      filename: `${reportName}.pdf`,
      pagebreak: {
        mode: "avoid-all",
        before: "#reportBody",
      },
    };
  
    const applyStyles = (selector: string, styles: Record<string, string>) => {
      const elements = component.querySelectorAll(selector) as unknown as HTMLElement[];
      elements.forEach((el) => {
        Object.assign(el.style, styles);
      });
      return elements;
    };
  
    // Style adjustments before generating the PDF
    const elementAdjustments = [
      { selector: '.locationName', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.cardTitle', styles: { transform: 'translate(0%, -40%)' } },
      { selector: '.arrowIconClass', styles: { transform: 'translate(0%, 15%)' } },
      { selector: '.groupName', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.groupPercentage', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.dot', styles: { marginTop: '15px' } },
      { selector: '.donutChart', styles: { transform: 'translate(0%, -25%)' } },
    ];
  
    // Save current styles
    const originalStyles = elementAdjustments.map(({ selector, styles }) => ({
      selector,
      elements: applyStyles(selector, styles),
      styles,
    }));
  
    setTimeout(() => {
      setIsDownloading(true);
    }, 0);
  
    html2pdf()
      .set(options)
      .from(component)
      .save()
      .then(() => {
        originalStyles.forEach(({ elements, styles }) => {
          elements.forEach((el) => {
            Object.keys(styles).forEach((styleKey) => {
              el.style[styleKey as any] = '';
            });
          });
        });
        setIsDownloading(false);
      })
      .catch((error) => {
        console.error('PDF generation failed:', error);
        setIsDownloading(false);
      });
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={840}
        height={800}
        overflow={'hidden auto'}
        sx={{
          ...scrollBarStyle,
        }}
      >
        <ModalHeader pb={0} sx={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            pb={'0.5em'}
          >
            <Text>Preview document</Text>
            <Button
              colorScheme="blue"
              size={'small'}
              sx={{ width: '150px' }}
              onClick={onDownload}
            >
              <Text fontSize={'md'}>Download</Text>
            </Button>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody height={'fit-content'} p={0}>
          <Stack
            height="100%"
            // p={'1em'}
            width="100%"
            sx={{ boxSizing: 'bored-box' }}
            id={'pdf-container'}
          >
            {reportType === 3 &&
              <EnergyConsumptionReportPdf
                group_id={groupId}
                device_ids={deviceIds}
                startDate={startDate}
                endDate={endDate}
                locations={locations}
                ibex_index={ibex_index}
                carbon_intensity={carbon_intensity}
                isDownloading={isDownloading}
              />
            }
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PreviewDocumentDialog
