import React, { useState, useMemo } from 'react'
import { Box, Grid, GridItem, Divider, Text, Flex, Button } from '@chakra-ui/react'
import { format } from 'date-fns'
import Header from '../../../app/components/Header'
import { useGetReportsHistoryQuery, useGetAllLocationsQuery } from '../api/reportsApi'

import { Pagination } from '../../../app/components/Pagination'
import { useLocation } from 'react-router-dom'
import { OverflowButton } from './components/OverflowButton'

export const ReportHistory = () => {
  const [locationId, setLocationId] = useState<undefined | number>()
  const [periodId, setPeriodId] = useState<undefined | number>()

  const location = useLocation()
  const pageNumber = Number(new URLSearchParams(location.search).get('page') || 1)
  const { data: reportsHistoryData } = useGetReportsHistoryQuery({
    page: Number(pageNumber),
    location: locationId,
  })
  const { data } = useGetAllLocationsQuery()

  const reportsHistory = reportsHistoryData?.results
  const totalPages = reportsHistoryData?.total_pages
  const reportsCount = reportsHistoryData?.count
  const locations = useMemo(
    () =>
      data?.results.map(({ id, title }) => ({
        id: id.toString(),
        name: title,
      })),
    [data?.results],
  )

  return (
    <>
      <Header title="Report History" />
      <Box p="24px">
        <Grid
          templateColumns="repeat(5, 1fr)"
          gap="0"
          bg="white"
          rounded="lg"
          mx="140px"
          alignItems="center"
        >
          <>
            <GridItem colSpan={3} py="16px" pl="24px">
              <Flex align="center" lineHeight="18px" h="30px">
                <Text color="text.dark">Total Reports ({reportsCount})</Text>
              </Flex>
            </GridItem>
            <GridItem colSpan={2}>
              <Flex direction="row" gap="2" justifyContent="left" mr="24px">
                {/* <SingleSelect
                  key="location-id"
                  items={locations}
                  setSelectedOption={(value) => {
                    setLocationId(value as number)
                  }}
                  selectedOption={locationId}
                  header="Select location"
                />
                <SingleSelect
                  key="period-id"
                  items={[
                    { name: '1 day', id: '1' },
                    { name: '1 week', id: '2' },
                    { name: '1 month', id: '3' },
                    { name: '3 months', id: '4' },
                    { name: '6 months', id: '5' },
                  ]}
                  setSelectedOption={(value) => {
                    setPeriodId(value as number)
                  }}
                  selectedOption={periodId}
                  header="Select Period"
                /> */}
              </Flex>
            </GridItem>

            <GridItem colSpan={5}>
              <Divider />
            </GridItem>
          </>
          {reportsHistory?.map((rh) => (
            <>
              <GridItem colSpan={2} py="16px" pl="24px">
                <Flex
                  align="center"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="18px"
                  h="30px"
                >
                  <Text color="text.dark">
                    {format(new Date(rh.from_date), 'dd MMM yyyy')} -{' '}
                    {format(new Date(rh.to_date), 'dd MMM yyyy')}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem colSpan={2}>
                {rh.locations.length === locations?.length
                  ? 'All locations'
                  : rh.locations
                      .map(
                        (rhLoc) =>
                          locations?.find((loc) => Number(loc.id) === rhLoc)?.name,
                      )
                      .join(' . ')}
              </GridItem>

              <GridItem colSpan={1} pr="24px">
                <Flex justifyContent="right" align="center">
                  <OverflowButton
                    locations={locations}
                    from={rh.from_date}
                    to={rh.to_date}
                  />
                </Flex>
              </GridItem>
              <GridItem colSpan={5}>
                <Divider />
              </GridItem>
            </>
          ))}
          {totalPages > 1 && (
            <GridItem colSpan={5}>
              <Pagination totalPages={totalPages} currentPage={pageNumber} />
            </GridItem>
          )}
        </Grid>
      </Box>
    </>
  )
}
