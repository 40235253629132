import React from 'react'
import {
  Button,
  Text,
  Flex,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuDivider,
  MenuItem,
  Tooltip,
} from '@chakra-ui/react'
import OverflowIcon from '../../../../assets/icons/Overflow'
import { RecurringReport } from '../../../../assets/icons/RecurringReport'
import { MailIcon } from '../../../../assets/icons/Mail'
import { Download } from '../../../../assets/icons/Download'
import ArrowRightIcon from '../../../../assets/icons/ArrowRightIcon'
import { format } from 'date-fns'

export const OverflowButton: React.FC<{
  locations: { id: string; name: string }[]
  from: string
  to: string
}> = ({ locations, from, to }) => {
  const pathname = `${btoa(
    locations?.map((loc) => Number(loc.id)).join(', '),
  )}?from=${format(new Date(from), 'yyyy-MM-dd')}&to=${format(
    new Date(to),
    'yyyy-MM-dd',
  )}`

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<OverflowIcon boxSize="24px" />}
        variant="outline"
        border="none"
      />
      <MenuList p="0" width="290px">
        <MenuItem
          icon={<ArrowRightIcon boxSize="24px" />}
          p="16px"
          as="a"
          href={pathname}
        >
          <Text>Go to Report</Text>
        </MenuItem>
        <MenuDivider m="0" />
        <Tooltip label="Unavailable" hasArrow placement="left">
          <MenuItem p="16px">
            <Flex>
              <Button variant="ghost" gap="16px" disabled px="0">
                <RecurringReport boxSize="24px" />
                <Text>Schedule recurring report</Text>
              </Button>
            </Flex>
          </MenuItem>
        </Tooltip>
        <MenuDivider m="0" />
        <Tooltip label="Unavailable" hasArrow placement="left">
          <MenuItem>
            <Flex>
              <Button variant="ghost" gap="16px" disabled px="0">
                <MailIcon boxSize="24px" />
                <Text>Send report via email</Text>
              </Button>
            </Flex>
          </MenuItem>
        </Tooltip>
        <MenuDivider m="0" />
        <MenuItem icon={<Download boxSize="24px" />} p="16px">
          <Text>Download as PDF</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
