import { Box, Flex, Stack, useMediaQuery } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import { Logo } from '../../../assets/icons/Logo'
import NavItems from '../../../app/components/Navigation'
import DrawerMenu from '../../../app/components/DrawerMenu'
import 'react-toastify/dist/ReactToastify.css'
import './toasts.css'
import AlarmToastManager from '../components/AlarmToastManager'
import React from 'react'
import { AlarmsCountContextProvider } from '../../../context/AlarmsCountContext'
import MethodiaLogo from '../../../assets/icons/Methodia'
import { METHODIA_SUBDOMAIN, getSubdomain } from '../../../utils/helpers'
import Cookies from 'js-cookie'

export const Layout: React.FC = () => {
  const methodiaAiotCookie = Cookies.get('aiot_methodia_user')

  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)')
  const bottomMenuItems = [
    { text: 'Leave feedback' },
    { text: 'Terms of service' },
    { text: 'Privacy policy' },
  ]

  const subdomain = getSubdomain();
  return (
    <>
      <AlarmsCountContextProvider>
        <Flex w="full" h="100vh" bgColor="#F7F9FD">
          <Flex
            direction="column"
            pl={8}
            pr={10}
            py={{ base: 4, sm: 8 }}
            borderWidth={1}
            bgColor="white"
            as="nav"
            justify="space-between"
            hidden={isSmallerThan1200}
          >
            <Stack
              direction="column"
              spacing={10}
              as="ul"
              justify="space-between"
              w="full"
            >
              {subdomain === METHODIA_SUBDOMAIN || methodiaAiotCookie ? (
                <MethodiaLogo w={150} h={50} />
              ) : (
                <Logo w={40} h={5} />
              )}

              <NavItems />
            </Stack>
            <Stack direction="column" as="ul" w="full" spacing={6} mt={20}>
              {bottomMenuItems.map(({ text }) => (
                <Box as="li" listStyleType="none" fontSize="sm" key={text}>
                  {text}
                </Box>
              ))}
            </Stack>
          </Flex>

          <Box borderWidth={1} py={{ base: 4, sm: 7 }} w="full" overflowX="auto">
            {isSmallerThan1200 && (
              <Stack
                direction="row"
                justify="space-between"
                w="full"
                mb={6}
                px={{ base: 6, lg: 0 }}
              >
                <Logo w={40} h={5} />
                <DrawerMenu />
              </Stack>
            )}
            <Box>
              <Box mx={{ base: 0, laptopM: 6 }}>
                <Outlet />
              </Box>
            </Box>
          </Box>
        </Flex>
        <AlarmToastManager />
      </AlarmsCountContextProvider>
    </>
  )
}
