import { pythonApi } from '../../../app/services/api/api'
import { getDashboardOverviewMockData } from '../../../mockData/dashboard'
import { ReportData, ReportHistoryData } from '../types'
import { ILocationResponse } from './locations'
import { EnergyConsumptionReport } from './types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLocations: builder.query<ILocationResponse, void>({
      query: () => ({
        url: `/locations/`,
        method: 'GET',
      }),
      providesTags: ['List'],
      transformResponse: (response: ILocationResponse, _meta, arg): ILocationResponse => {
        const mockResponse = getDashboardOverviewMockData()
        return mockResponse ? (mockResponse as unknown as ILocationResponse) : response
      },
    }),
    getReportData: builder.query<ReportData.Data, ReportData.QueryParams>({
      query: ({ from_date, to_date, location_ids }) => {
        const searchParams = new URLSearchParams()
        location_ids.forEach((id) => searchParams.append('location_ids', id))
        searchParams.append('from_date', from_date)
        searchParams.append('to_date', to_date)
        return {
          url: `/locations/report-data/?${searchParams}`,
          method: 'GET',
        }
      },
    }),
    getReportsHistory: builder.query<
      ReportHistoryData.Data,
      { page: number; location?: number }
    >({
      query: ({ page, location }) => {
        const queryParams = new URLSearchParams()
        queryParams.set('page', page?.toString())
        location && queryParams.set('locations', location?.toString())

        return {
          url: `/reports/?${queryParams.toString()}`,
          method: 'GET',
        }
      },
    }),
    getEnergyConsumptionReport: builder.query<EnergyConsumptionReport, ReportData.QueryParams>({
      query: ({location_ids, from_date, to_date, group_id, device_ids, carbon_intensity, ibex_index} : ReportData.QueryParams & {group_id: string, device_ids: string[]}) => {
        const queryParams = new URLSearchParams()
        queryParams.set('location_ids', location_ids.join(','))
        queryParams.set("from_date", from_date)
        queryParams.set("to_date", to_date)
        if (group_id){
          queryParams.set("groups_ids", group_id)
        }
        if(carbon_intensity){
          queryParams.set("carbon_intensity", carbon_intensity)
        }
        if(ibex_index){
          queryParams.set("ibex_index", ibex_index)
        }
        queryParams.set("device_ids", device_ids.join(','))


        return {
          url: `/energy-consumption/?${queryParams.toString()}`,
          method: 'GET',
        }
      }
    }),
    getTrendReportData: builder.mutation<any, any>({
      query: (body) => ({
        url: `/product-profile/`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetAllLocationsQuery,
  useGetReportDataQuery,
  useGetReportsHistoryQuery,
  useGetTrendReportDataMutation,
  useGetEnergyConsumptionReportQuery,
} = extendedApi
export default extendedApi
